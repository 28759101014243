import moment from 'moment';
import Constants from '../../../constants';

export class BookingDto implements IBookingDto{
    id: number;
    user: IPublicUserDto;
    userId: string;
    session: ISessionDto;
    sessionId: number;
    amount: number;
    credits: number;
    excessiveFee: number;
    userDiscount: number;
    status: string;
    paymentType: string;
    changeStatusComment: string;
    transactionId: string;
    transactionComments: string;
    transactionDate: string;
    creditsTransaction: ICreditsTransactionDto;
    creditsTransactionId: number;
    refund: number;
    refundTransactionId: string;
    refundedTransactionId: string;
    refundDateTime: string;
    cancellationDateTime: string;
    salesTax: number;
    videoLinkId: string;
    addons: IAddonDto[];
    group: IGroupDto;
    groupId: number;
    paymentSystemId: number;
    payoffByUserName: string;
    payoffDateTime: moment.Moment;

    constructor(dto?: any) {
        this.id = dto?.id ?? 0;
        this.user = dto?.user ?? null;
        this.userId = dto?.userId ?? 0;
        this.session = dto?.session ?? null;
        this.sessionId = dto?.sessionId ?? 0;
        this.amount = dto?.amount ?? 0;
        this.credits = dto?.credits ?? 0;
        this.excessiveFee = dto?.excessiveFee ?? 0;
        this.userDiscount = dto?.userDiscount ?? 0;
        this.status = dto?.status ?? '';
        this.paymentType = dto?.paymentType ?? '';
        this.changeStatusComment = dto?.changeStatusComment ?? '';
        this.transactionId = dto?.transactionId ?? '';
        this.creditsTransactionId = dto?.creditsTransactionId ?? 0;
        this.transactionComments = dto?.transactionComments ?? '';
        this.transactionDate = dto?.transactionDate ?? '';
        this.refund = dto?.refund ?? 0;
        this.refundTransactionId = dto?.refundTransactionId ?? '';
        this.refundDateTime = dto?.refundDateTime ?? '';
        this.cancellationDateTime = dto?.cancellationDateTime ?? '';
        this.salesTax = dto?.salesTax ?? 0;
        this.videoLinkId = dto?.videoLinkId ?? '';
        this.addons = dto?.addons ?? [];
        this.group = dto?.group ?? null;
        this.groupId = dto?.groupId ?? 0;
        this.paymentSystemId = dto?.paymentSystemId ?? 0;
        this.payoffByUserName = dto?.payoffByUserName ?? '';
        this.payoffDateTime = dto?.payoffDateTime ? moment(dto.payoffDateTime, Constants.DateTime.API_FORMAT) : moment.min();
    }
}