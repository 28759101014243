import moment from 'moment';

import Constants from '../../../constants';
import { IAddonStoreState } from '../addons/addonStoreState';
import { IPublicUserStoreState } from '../users';
import { ISessionStoreState } from '../sessions/sessionStoreState';
import { IGroupStoreState } from '../groups';

export interface IBookingStoreState{
    id: number;
    user: IPublicUserStoreState;
    userId: string;
    session: ISessionStoreState;
    sessionId: number;
    amount: number;
    credits: number;
    excessiveFee: number;
    userDiscount: number;
    status: string;
    paymentType: string;
    changeStatusComment: string;
    transactionId: string;
    transactionComments: string;
    transactionDate: moment.Moment;
    refund: number;
    refundTransactionId: string;
    refundedTransactionId: string;
    refundDateTime: moment.Moment;
    cancellationDateTime: moment.Moment;
    salesTax: number;
    videoLinkId: string;
    addons: Array<IAddonStoreState>;
    group: IGroupStoreState;
    groupId: number;
    paymentSystemId: number;
    payoffByUserName: string;
    payoffDateTime: moment.Moment;
}

export class BookingStoreState implements IBookingStoreState{
    id: number;
    user: IPublicUserStoreState;
    userId: string;
    session: ISessionStoreState;
    sessionId: number;
    amount: number;
    credits: number;
    excessiveFee: number;
    userDiscount: number;
    status: string;
    paymentType: string;
    changeStatusComment: string;
    transactionId: string;
    transactionComments: string;
    transactionDate: moment.Moment;
    refund: number;
    refundTransactionId: string;
    refundedTransactionId: string;
    refundDateTime: moment.Moment;
    cancellationDateTime: moment.Moment;
    salesTax: number;
    videoLinkId: string;
    addons: Array<IAddonStoreState>;
    group: IGroupStoreState;
    groupId: number;
    paymentSystemId: number;
    payoffByUserName: string;
    payoffDateTime: moment.Moment;

    constructor(dto?: any) {
        this.id = dto?.id ?? 0;
        this.user = dto?.user ?? null;
        this.userId = dto?.userId ?? '';
        this.session = dto?.session ?? '';
        this.sessionId = dto?.sessionId ?? '';
        this.amount = dto?.amount ?? 0;
        this.credits = dto?.credits ?? 0;
        this.excessiveFee = dto?.excessiveFee ?? 0;
        this.userDiscount = dto?.userDiscount ?? 0;
        this.status = dto?.status ?? '';
        this.paymentType = dto?.paymentType ?? '';
        this.changeStatusComment = dto?.changeStatusComment ?? '';
        this.transactionId = dto?.transactionId ?? '';
        this.transactionComments = dto?.transactionComments ?? '';
        this.transactionDate = dto && dto.transactionDate ? moment(dto.transactionDate, Constants.DateTime.API_FORMAT) : moment.min();
        this.refund = dto?.refund ?? 0;
        this.refundTransactionId = dto?.refundTransactionId ?? '';
        this.refundDateTime = dto && dto.refundDateTime ?  moment(dto.refundDateTime, Constants.DateTime.API_FORMAT) : moment.min();
        this.cancellationDateTime = dto && dto.cancellationDateTime ?  moment(dto.cancellationDateTime, Constants.DateTime.API_FORMAT) : moment.min() ;
        this.salesTax = dto?.salesTax ?? 0;
        this.videoLinkId = dto?.videoLinkId ?? '';
        this.addons = dto?.addons ?? [];
        this.group = dto?.group ?? null;
        this.groupId = dto?.groupId  ?? '';
        this.paymentSystemId = dto?.paymentSystemId ?? 0;
        this.payoffByUserName = dto?.payoffByUserName ?? '';
        this.payoffDateTime = dto && dto.payoffDateTime ?  moment(dto.payoffDateTime, Constants.DateTime.API_FORMAT) : moment.min();
    }
}